import React, { useRef, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Anser from 'anser';
import { solarized } from '../theme/colors';
import './logs.css';
export default function Logs(_a) {
    var output = _a.output;
    var messagesEndRef = useRef(null);
    useEffect(function () {
        var el = messagesEndRef.current;
        el.scrollTop = el.scrollHeight;
    });
    if (!(output)) {
        output = "Please connect your Tidbyt";
    }
    var html = Anser.ansiToHtml(output, { use_classes: true });
    return (React.createElement(Box, { sx: { minWidth: '100%' } },
        React.createElement(Paper, { sx: { borderRadius: 2, backgroundColor: solarized.base03, padding: 2 } },
            React.createElement(Typography, { ref: messagesEndRef, style: { whiteSpace: 'pre', overflowX: 'auto', overflowY: 'scroll', marginTop: 0, height: '60vh' }, mt: 4, mb: 0, variant: "body2", dangerouslySetInnerHTML: { __html: html } }))));
}
