import React from 'react';
import { Link } from 'react-router-dom';
import { solarized } from '../theme/colors';
import { Typography, Box, Grid } from '@mui/material';
export default function Highlight(_a) {
    var heading = _a.heading, desc = _a.desc, href = _a.href, icon = _a.icon;
    return (React.createElement(Link, { style: { color: "inherit", textDecoration: "inherit" }, to: href },
        React.createElement(Box, { sx: {
                border: "2px solid ".concat(solarized.base02),
                borderRadius: "12px",
                padding: "28px",
                transition: 'border 100ms ease-out',
                ':hover': {
                    border: "2px solid ".concat(solarized.cyan),
                },
                ':hover > div > div > h2': {
                    textDecoration: "underline ".concat(solarized.cyan),
                }
            } },
            React.createElement(Grid, { container: true, spacing: 0 },
                React.createElement(Grid, { sx: { justifyContent: "center", display: "flex", alignItems: "center" }, item: true, xs: 2 }, icon),
                React.createElement(Grid, { item: true, xs: 10, display: { xs: "none", md: "block" } },
                    React.createElement(Typography, { sx: {
                            color: solarized.base1,
                        }, variant: "h2" }, heading)),
                React.createElement(Grid, { item: true, xs: 10, display: { xs: "block", md: "none" } },
                    React.createElement(Typography, { sx: {
                            color: solarized.base1,
                        }, variant: "h3" }, heading)),
                React.createElement(Grid, { item: true, xs: 2 }),
                React.createElement(Grid, { item: true, xs: 10 },
                    React.createElement(Typography, { sx: {
                            color: solarized.cyan,
                        } }, desc))))));
}
