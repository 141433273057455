import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Box, Paper, Typography } from '@mui/material';
import { solarizedDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { solarized } from '../theme/colors';
export default function CodeBlock(_a) {
    var inline = _a.inline, className = _a.className, children = _a.children, code = _a.code;
    if (inline) {
        return (React.createElement(Box, { component: 'code', sx: {
                fontWeight: '500',
                paddingLeft: '0.3rem',
                paddingRight: '0.3rem',
                fontFamily: 'monospace',
            } }, children));
    }
    var language = 'python';
    var match = /language-(\w+)/.exec(className || '');
    if (match) {
        language = match[1];
    }
    if (language.toLowerCase() === 'starlark') {
        language = 'python';
    }
    if (!code) {
        code = String(children).replace(/\n$/, '');
    }
    return (React.createElement(Box, { sx: { minWidth: '100%' } },
        React.createElement(Paper, { sx: { borderRadius: 2, backgroundColor: solarized.base03 } },
            React.createElement(Typography, { mt: 4, mb: 0, variant: "body2" },
                React.createElement(SyntaxHighlighter, { customStyle: {
                        background: 'none',
                    }, children: code, style: solarizedDark, language: language })))));
}
