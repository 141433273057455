import React from 'react';
import { BrowserRouter, Route, Routes as RouterRoutes, Navigate } from "react-router-dom";
import Main from '../main/Main';
import Home from '../home/Home';
import { getDocs } from './docs';
import PublicAPI from '../publicapi/PublicAPI';
import ScrollToTop from './ScrollToTop';
import NotFound from '../notfound/NotFound';
import Console from '../console/Console';
export default function Routes() {
    function genRoutes() {
        var routes = [];
        var docs = getDocs();
        docs.forEach(function (element) {
            routes.push(React.createElement(Route, { key: element.path, path: element.url, element: React.createElement(Main, { source: element.source }) }));
        });
        return routes;
    }
    return (React.createElement(BrowserRouter, null,
        React.createElement(ScrollToTop, null),
        React.createElement(RouterRoutes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Home, null) }),
            React.createElement(Route, { path: '/console', element: React.createElement(Main, null,
                    React.createElement(Console, null)) }),
            React.createElement(Route, { path: "/docs/api", element: React.createElement(Main, null,
                    React.createElement(PublicAPI, null)) }),
            genRoutes(),
            React.createElement(Route, { path: '/docs', element: React.createElement(Navigate, { to: '/docs/overview/getting-started' }) }),
            React.createElement(Route, { path: '/docs/overview', element: React.createElement(Navigate, { to: '/docs/overview/getting-started' }) }),
            React.createElement(Route, { path: '/docs/build', element: React.createElement(Navigate, { to: '/docs/build/build-for-tidbyt' }) }),
            React.createElement(Route, { path: '/docs/integrate', element: React.createElement(Navigate, { to: '/docs/integrate/pushing-apps' }) }),
            React.createElement(Route, { path: '/docs/publish', element: React.createElement(Navigate, { to: '/docs/publish/community-apps' }) }),
            React.createElement(Route, { path: '/docs/engage', element: React.createElement(Navigate, { to: '/docs/engage/community' }) }),
            React.createElement(Route, { path: '/docs/tidbyt-api', element: React.createElement(Navigate, { to: '/docs/api' }) }),
            React.createElement(Route, { path: '*', element: React.createElement(Main, null,
                    React.createElement(NotFound, null)) }))));
}
