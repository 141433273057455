// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sAsBQANQ3Me7cNoYFofV {
    animation: wqtMRC0Gh_lYSGrBN8xi 30s infinite linear;
}

@keyframes wqtMRC0Gh_lYSGrBN8xi {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/home/home.css"],"names":[],"mappings":"AAAA;IACI,mDAAuC;AAC3C;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".rotate {\n    animation: rotation 30s infinite linear;\n}\n\n@keyframes rotation {\n    from {\n        transform: rotate(0deg);\n    }\n\n    to {\n        transform: rotate(359deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotate": `sAsBQANQ3Me7cNoYFofV`,
	"rotation": `wqtMRC0Gh_lYSGrBN8xi`
};
export default ___CSS_LOADER_EXPORT___;
