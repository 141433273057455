import React from 'react';
import { ListItem, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
export default function Item(_a) {
    var index = _a.index, ordered = _a.ordered, children = _a.children;
    if (ordered) {
        return (React.createElement(ListItem, { key: index },
            React.createElement(Typography, { component: "span", variant: "body2" },
                React.createElement("strong", { style: { paddingRight: 10 } }, "".concat(index + 1, ".")),
                children)));
    }
    return (React.createElement(ListItem, { sx: { display: 'block' }, key: index },
        React.createElement(Typography, { component: "span", variant: "body2" },
            React.createElement(CircleIcon, { sx: { fontSize: 10, color: '#000', paddingRight: 1 } }),
            children)));
}
