import React, { useState } from 'react';
import Handyman from '@mui/icons-material/Handyman';
import Forum from '@mui/icons-material/Forum';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import Autorenew from '@mui/icons-material/Autorenew';
import Highlight from './Highlight';
import css from './home.css';
import Header from '../header/Header';
import Navigation from '../navigation/Navigation';
import LogoRound from '../header/LogoRound';
import Content from '../content/Content';
import RotateText from '../header/RotateText';
import { solarized } from '../theme/colors';
import { Grid, Box, Toolbar, Container } from '@mui/material';
export default function Home() {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(Box, { sx: { flexGrow: 1, backgroundColor: solarized.base03 } },
        React.createElement(Header, { open: open, handleOpen: handleOpen }),
        React.createElement(Navigation, { open: open, handleOpen: handleOpen, handleClose: handleClose }),
        React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" },
            React.createElement(Content, { disabled: true },
                React.createElement(Container, { sx: { mt: 4 } },
                    React.createElement(Toolbar, null),
                    React.createElement(Grid, { container: true, spacing: 4 },
                        React.createElement(Grid, { sx: { justifyContent: "center", display: "flex", alignItems: "center", m: 2 }, item: true, xs: 12 },
                            React.createElement(Box, { sx: { zIndex: 1, position: "absolute" } },
                                React.createElement(LogoRound, { width: "180px" })),
                            React.createElement(Box, { className: css.rotate },
                                React.createElement(RotateText, { color: solarized.base01, width: "264px" }))),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Highlight, { href: "/docs/build/build-for-tidbyt", heading: "Build", desc: "Build an app for your Tidbyt", icon: React.createElement(Handyman, { sx: { fontSize: 48 } }) })),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Highlight, { href: "/docs/publish/community-apps", heading: "Publish", desc: "Publish your app for all Tidbyt users", icon: React.createElement(RocketLaunch, { sx: { fontSize: 48 } }) })),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Highlight, { href: "/docs/integrate/pushing-apps", heading: "Integrate", desc: "Integrate with the Tidbyt API", icon: React.createElement(Autorenew, { sx: { fontSize: 48 } }) })),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Highlight, { href: "/docs/engage/community", heading: "Engage", desc: "Learn from the Tidbyt Community", icon: React.createElement(Forum, { sx: { fontSize: 48 } }) })),
                        React.createElement(Grid, { item: true, xs: 12, display: { xs: "block", md: "none" } },
                            React.createElement(Box, { height: "80px" }))))))));
}
