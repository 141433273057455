import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ThemeProvider from '@mui/system/ThemeProvider';
import { theme } from './theme';
import './styles.css';
export default function AppTheme(_a) {
    var children = _a.children;
    library.add(fas, fab);
    return (React.createElement(ThemeProvider, { theme: theme }, children));
}
