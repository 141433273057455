var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar as MuiAppBar, styled } from '@mui/material';
import { drawerWidth } from '../content/Content';
import { solarized } from '../theme/colors';
var StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: function (prop) { return prop !== 'open'; },
})(function (_a) {
    var theme = _a.theme, open = _a.open;
    return (__assign({ transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }) }, (open && {
        width: "calc(100% - ".concat(drawerWidth, "px)"),
        marginLeft: "".concat(drawerWidth, "px"),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    })));
});
export default function AppBar(_a) {
    var children = _a.children, open = _a.open;
    var theme = useTheme();
    var desktop = useMediaQuery(theme.breakpoints.up('sm'));
    if (desktop) {
        return (React.createElement(StyledAppBar, { position: "fixed", open: open, elevation: 0, sx: { zIndex: function (theme) { return theme.zIndex.drawer + 1; }, backgroundColor: solarized.base03 } }, children));
    }
    return (React.createElement(MuiAppBar, { position: "fixed", sx: { backgroundColor: solarized.base03 }, elevation: 0 }, children));
}
