import React from 'react';
import { Typography, Box } from '@mui/material';
import Link from './Link';
export default function Heading(_a) {
    var level = _a.level, children = _a.children, id = _a.id;
    var variant = "h".concat(level);
    var marginTop = calcMarginTop(level);
    var marginBottom = calcMarginBottom(level);
    function calcMarginTop(level) {
        switch (level) {
            default:
                return 3;
        }
    }
    function calcMarginBottom(level) {
        switch (level) {
            default:
                return 0;
        }
    }
    var link = id ? (React.createElement(Link, { href: "#".concat(id), mt: marginTop, mb: marginBottom, 
        // @ts-ignore
        underline: "hover", mr: 1 },
        React.createElement("span", null, "#"))) : null;
    return (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
        link,
        React.createElement(Typography, { mt: marginTop, mb: marginBottom, 
            // @ts-ignore
            variant: variant, id: id }, children))); // @ts-ignore
}
