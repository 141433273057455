var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function RoundLogo(props) {
    return (React.createElement("svg", __assign({ "data-name": "round-logo", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 69.46 69.46" }, props),
        React.createElement("path", { d: "M34.73 69.46A34.73 34.73 0 1 0 0 34.73a34.73 34.73 0 0 0 34.73 34.73" }),
        React.createElement("path", { style: {
                fill: "#e0b0ff",
            }, d: "M31.43 37.21h6.61v19.93h-6.61z" }),
        React.createElement("path", { style: {
                fill: "#7d8af7",
            }, d: "M31.43 20.46h6.61v16.75h-6.61z" }),
        React.createElement("path", { style: {
                fill: "#ffb5f5",
            }, d: "M38.04 14.65h9.21v5.81h-9.21z" }),
        React.createElement("path", { style: {
                fill: "#78decc",
            }, d: "M22.22 14.65h15.82v5.81H22.22z" })));
}
