var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Logo(props) {
    return (React.createElement("svg", __assign({ "data-name": "logo", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 215.01 32.01" }, props),
        React.createElement("path", { style: { fill: props.color }, d: "M76.77 27.9h2.36c4.94 0 6.59-3.8 6.59-12.56 0-7.75-1.64-11.16-6.55-11.16h-2.4ZM71.76 0h7.92c7.95 0 11.18 5.24 11.18 15.29 0 10.83-3 16.72-11.64 16.72h-7.46ZM119.75 28.08h3.72c3.17 0 5-1.74 5-5.54 0-3.64-1.65-5.64-5.6-5.64h-3.08ZM122.82 13c3.72 0 4.84-1.52 4.84-4.51S126.11 4 123.11 4h-3.36v9Zm-7.95-13h9.27c5.3 0 8.31 3 8.31 8 0 4.12-2 6.05-3.78 6.71 2.32.9 4.75 3 4.75 7.89 0 6-3.79 9.4-9.44 9.4h-9.11ZM161.75 32V20.39a2.13 2.13 0 0 0-.21-1L153.45 0H159c1.93 5.15 4.43 11.79 5.38 15.09 1.16-3.64 3.68-10.19 5.45-15.09h5.25l-8.18 19.48a2.56 2.56 0 0 0-.17 1V32Z" }),
        React.createElement("path", { style: { fill: props.color }, d: "M0 0v4.37l6.94.01-.11 27.59 5.09.04V4.37h6.94V0H0zM41.47 0h4.98v32.01h-4.98zM196.15 0v4.37h6.94v27.64h4.98V4.38l6.94-.01V0h-18.86z" })));
}
