var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Drawer, IconButton, styled, Divider, } from '@mui/material';
import { getDirs } from './docs';
import Folder from './Folder';
import { drawerWidth } from '../content/Content';
import { solarized } from '../theme/colors';
var DrawerHeader = styled('div')(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ display: 'flex', alignItems: 'center', padding: theme.spacing(0, 1) }, theme.mixins.toolbar), { justifyContent: 'flex-end' }));
});
export default function Navigation(_a) {
    var open = _a.open, handleOpen = _a.handleOpen, handleClose = _a.handleClose;
    var theme = useTheme();
    var desktop = useMediaQuery(theme.breakpoints.up('sm'));
    var dirs = getDirs();
    function genFolders(close) {
        var folders = [];
        dirs.forEach(function (value, key) { return (folders.push(React.createElement(Folder, { key: key, title: key, docs: value, handleClose: close }))); });
        return folders;
    }
    var variant = 'temporary';
    if (desktop) {
        variant = 'persistent';
    }
    var close = handleClose;
    if (desktop) {
        close = function () { };
    }
    return (React.createElement(Drawer, { sx: {
            width: drawerWidth,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
            },
        }, variant: variant, anchor: "left", open: open, onClose: close, PaperProps: {
            sx: {
                backgroundColor: solarized.base03,
            }
        } },
        React.createElement(DrawerHeader, null,
            React.createElement(IconButton, { onClick: handleClose },
                React.createElement(ChevronLeft, null))),
        React.createElement(Divider, null),
        genFolders(close)));
}
