import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
export default function Image(_a) {
    var src = _a.src, alt = _a.alt, title = _a.title, children = _a.children;
    return (React.createElement(Box, { sx: { flexGrow: 1 } },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12, md: 8 },
                React.createElement(Paper, { sx: {
                        borderRadius: 2,
                        marginTop: 4,
                        maxWidth: '100%',
                        height: 'auto',
                    }, component: "img", alt: alt, src: src })))));
}
