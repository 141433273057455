var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export var drawerWidth = 240;
var Shifted = styled(Box, { shouldForwardProp: function (prop) { return prop !== 'open'; } })(function (_a) {
    var theme = _a.theme, open = _a.open;
    return (__assign({ flexGrow: 1, padding: theme.spacing(3), transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }) }, (open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "".concat(drawerWidth, "px"),
    })));
});
export default function Content(_a) {
    var open = _a.open, children = _a.children;
    var theme = useTheme();
    var desktop = useMediaQuery(theme.breakpoints.up('sm'));
    if (desktop) {
        return (React.createElement(Shifted, { open: open }, children));
    }
    return (React.createElement(Box, { sx: {
            flexGrow: 1,
            padding: theme.spacing(3),
        } }, children));
}
