// TODO: all of the sorting is implicit from Webpack. We should actually sort
// everything based on the number prefix.
var DOCS = require.context('../../docs', true, /\.(md)$/);
export function getDirs() {
    var dirs = new Map();
    DOCS.keys().forEach(function (item) {
        var parts = item.split("/");
        if (parts.length > 2) {
            if (!dirs.has(parts[1])) {
                dirs.set(parts[1], new Array);
            }
            dirs.get(parts[1]).push(genDoc(item));
        }
    });
    return dirs;
}
export function getDocs() {
    var docs = [];
    DOCS.keys().forEach(function (item) {
        docs.push(genDoc(item));
    });
    return docs;
}
export function genDoc(path) {
    var parts = path.split("/");
    var name = parts[parts.length - 1].replace('.md', '');
    var doc = {
        name: genName(name),
        url: genURL(path),
        path: path,
        source: DOCS(path).default,
    };
    return doc;
}
export function genURL(path) {
    var converted = path.replace('.md', '').replace('../', '').replace('./', '');
    var removeSort = converted.replace(/\d\d_/g, '');
    var convertUnderscores = removeSort.replace(/_/g, '-');
    var url = "/docs/".concat(convertUnderscores);
    return url;
}
// TODO: support relative paths in nested directories. Example ../ in multiple
// levels of nesting.
export function convertRelativePath(path, currURL) {
    // If the doc is in the same directory, return the current url prefixed to
    // the path.
    var pruneDocs = currURL.replace('/docs/', '');
    var parts = pruneDocs.split('/');
    if (path.startsWith('./') && parts.length > 1) {
        var dirs = parts.slice(0, parts.length - 1);
        var prefix = dirs.join('/');
        return path.replace('./', "".concat(prefix, "/"));
    }
    // If the doc is relatively linked, it works by default so long as it's relative
    // to the doc root.
    return path;
}
export function genName(path) {
    var parts = path.split("/");
    var fileName = parts[parts.length - 1].replace('.md', '');
    var removeSort = fileName.replace(/\d\d_/g, '');
    var name = titleCase(removeSort.replace(/_/g, ' '));
    return name;
}
export function cleanFolderName(name) {
    var removeSort = name.replace(/\d\d_/g, '');
    return titleCase(removeSort.replace(/_/g, ' '));
}
export function titleCase(input) {
    if (input.toUpperCase() === input) {
        return input;
    }
    var str = input.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}
