import { configureStore, createSlice } from '@reduxjs/toolkit';
var slice = createSlice({
    name: 'test',
    initialState: 0,
    reducers: {
        increment: function (state, action) { return state + action.payload; },
    },
});
export default configureStore({
    reducer: {
        posts: slice.reducer,
    },
});
