import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListSubheader, ListItemText, Divider, } from '@mui/material';
import { cleanFolderName } from './docs';
import { solarized } from '../theme/colors';
export default function Folder(_a) {
    var title = _a.title, docs = _a.docs, handleClose = _a.handleClose;
    function genFolderItems() {
        var items = [];
        docs.forEach(function (value) { return (items.push(React.createElement(Link, { key: value.path, style: { color: "inherit", textDecoration: "inherit" }, to: value.url },
            React.createElement(ListItem, { disablePadding: true },
                React.createElement(ListItemButton, { onClick: handleClose },
                    React.createElement(ListItemText, { primary: value.name })))))); });
        if (cleanFolderName(title) === 'Integrate') {
            items.push(React.createElement(Link, { key: '/docs/api', style: { color: "inherit", textDecoration: "inherit" }, to: '/docs/api' },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemButton, { onClick: handleClose },
                        React.createElement(ListItemText, { primary: 'API Operations' })))));
        }
        return items;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(List, { subheader: React.createElement(ListSubheader, { sx: { backgroundColor: solarized.base03 }, component: "div", id: "nested-list-subheader" }, cleanFolderName(title)) }, genFolderItems()),
        React.createElement(Divider, null)));
}
