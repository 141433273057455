import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import img from '../../docs/img/404.webp';
export default function NotFound() {
    return (React.createElement(Box, { sx: { flexGrow: 1 } },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Paper, { sx: {
                        borderRadius: 2,
                        marginTop: 4,
                        maxWidth: '100%',
                        height: 'auto',
                    }, component: "img", alt: 'not found', src: img })))));
}
