import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../header/Header';
import Markdown from '../markdown/Markdown';
import Navigation from '../navigation/Navigation';
import Content from '../content/Content';
import { Container, Box, Toolbar } from '@mui/material';
export default function Main(_a) {
    var source = _a.source, children = _a.children;
    var theme = useTheme();
    var matches = useMediaQuery(theme.breakpoints.up('sm'));
    var _b = useState(matches), open = _b[0], setOpen = _b[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(Box, { sx: { flexGrow: 1 } },
        React.createElement(Header, { open: open, handleOpen: handleOpen }),
        React.createElement(Navigation, { open: open, handleOpen: handleOpen, handleClose: handleClose }),
        React.createElement(Toolbar, null),
        React.createElement(Content, { open: open },
            React.createElement(Container, { maxWidth: "md" },
                React.createElement(Markdown, { source: source }),
                children)),
        React.createElement(Box, { height: "80px" })));
}
