var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import GitHub from '@mui/icons-material/GitHub';
import { Box, Toolbar, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from './AppBar';
import Logo from './Logo';
import { solarized } from '../theme/colors';
export default function Header(_a) {
    var open = _a.open, handleOpen = _a.handleOpen;
    var theme = useTheme();
    var desktop = useMediaQuery(theme.breakpoints.up('sm'));
    var styles = {
        mr: 2
    };
    if (desktop) {
        styles = __assign(__assign({}, styles), (open && { display: 'none' }));
    }
    return (React.createElement(AppBar, { open: open },
        React.createElement(Toolbar, null,
            React.createElement(IconButton, { size: "large", edge: "start", color: "inherit", "aria-label": "open drawer", onClick: handleOpen, sx: styles },
                React.createElement(MenuIcon, null)),
            React.createElement(Box, { sx: { flexGrow: 1 } }),
            React.createElement(Link, { to: "/" },
                React.createElement(Box, { sx: { display: { xs: 'flex', md: 'flex' }, height: '16px' } },
                    React.createElement(Logo, { color: solarized.base1, height: '16px' }))),
            React.createElement(Box, { sx: { flexGrow: 1 } }),
            React.createElement(IconButton, { size: "large", edge: "end", href: "https://github.com/tidbyt" },
                React.createElement(GitHub, null)))));
}
