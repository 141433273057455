// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/VisbyCF-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/VisbyCF-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
    margin: 0;
    height: 100%;
}

@font-face {
    font-family: 'VisbyCF';
    src: local('VisbyCF'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
    font-family: 'VisbyCFBold';
    src: local('VisbyCF'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
}`, "",{"version":3,"sources":["webpack://./src/theme/styles.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,iFAA0E;AAC9E;;AAEA;IACI,0BAA0B;IAC1B,iFAAuE;AAC3E","sourcesContent":["html,\nbody {\n    margin: 0;\n    height: 100%;\n}\n\n@font-face {\n    font-family: 'VisbyCF';\n    src: local('VisbyCF'), url(./fonts/VisbyCF-Regular.otf) format('opentype');\n}\n\n@font-face {\n    font-family: 'VisbyCFBold';\n    src: local('VisbyCF'), url(./fonts/VisbyCF-Bold.otf) format('opentype');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
